/** @jsx jsx */
import { Box, Flex, Image, jsx } from "theme-ui";
import LayoutHome from "../layout/LayoutHome";
import peepal from "../../static/imgs/peepal-150x105.png";
import neem from "../../static/imgs/neem-150x150.png";
import karanj from "../../static/imgs/karanj-150x150.png";
import rosewood from "../../static/imgs/rosewood-150x150.png";
import shisham from "../../static/imgs/shisham-150x150.png";
import palash from "../../static/imgs/palash-150x135.png";
import saptaparni from "../../static/imgs/saptaparni-150x119.png";
import alexander from "../../static/imgs/alexander-150x150.png";
import asoka from "../../static/imgs/asoka-150x117.png";
import champaka from "../../static/imgs/champaka.png";
import bakula from "../../static/imgs/bakula-150x150.png";

const EachOneTeachOne = () => {
  return (
    <LayoutHome
      seoTitle="Each One Teach One To Plant One - Donations"
      contentHeader={<h3> Each One, Teach One to Plant One</h3>}
      contentHeaderStyle="contentHeaderText"
    >
      <Box sx={{ mb: 2, mt: 3 }}>
        <p>
          <strong>Peepal / Raavi Chetlu/ Arasu Mara/ Arali Mara</strong>
          <i> Ficus religiosa</i>
        </p>
        <Flex sx={{ flexDirection: ["column", null, "row", null] }}>
          <Image
            sx={{ flexShrink: 0, alignSelf: "flex-start", pr: 3 }}
            src={peepal}
          />
          <p>
            The Peepal tree is considered the most sacred of all trees in
            India.. It is a keystone species as it harbours large number of
            birds, insects and small arboreal animals. It is a very good species
            used for fuel, fodder and medicinal purposes. Bark of its root is
            one amongst the five most valued source of medicine by ancient
            Indian physicians.
          </p>
        </Flex>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Neem / Vepa Chetlu</strong> <i> Azadirachta indica</i>
        </p>
        <Flex sx={{ flexDirection: ["column", null, "row", null] }}>
          <Image
            sx={{ flexShrink: 0, alignSelf: "flex-start", pr: 3 }}
            src={neem}
          />
          <p>
            <b>Neem is the State tree of Andhra Pradesh.</b>
            It is one of the thrifty trees which have much less demand on soil
            fertility or moisture content. It is a highly useful, large
            evergreen tree, with matchless medicinal properties. The heartwood
            is hard, durable, and is used for various purposes. Bark and leaves
            are used in a large number of medicinal concoctions all over India.
            Seeds yield oil which has industrial uses. Neem cakes are used as
            fertilizer. Leaves are used as fodder.
          </p>
        </Flex>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Karanj / Kanuga/ Pungam</strong> <i> Pongamia pinnata</i>
        </p>
        <Flex sx={{ flexDirection: ["column", null, "row", null] }}>
          <Image
            sx={{ flexShrink: 0, alignSelf: "flex-start", pr: 3 }}
            src={karanj}
          />
          <p>
            It is a multipurpose tree. It is a very useful tree in rural
            economy, being a source for an excellent fuelwood. It is also used
            for making rafters, carts, agricultural implements and veneers. Its
            leaves keep away termites, and make a coveted green manure. In
            Ayurveda, bark, leaf and seed of the tree are used as medicines many
            human ailments. The flowers exude peculiar fragrance, and are full
            of nectar. The seeds yield non-edible oil, used as an alternative to
            diesel.
          </p>
        </Flex>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Indian Rosewood </strong> <i> Dalbergia latifolia</i>
        </p>
        <Flex sx={{ flexDirection: ["column", null, "row", null] }}>
          <Image
            sx={{ flexShrink: 0, alignSelf: "flex-start", pr: 3 }}
            src={rosewood}
          />
          <p>
            It is a large tree with straight, large bole and spreading branches.
            The dark-purple heartwood with black streaks is extremely hard and
            heavy. The stem yields a bitter tonic used in traditional systems of
            medicine. It is an ornamental tree suitable for planting in avenues,
            along roads, and in parks for shade. The seeds are edible.
          </p>
        </Flex>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Shisham/Sissoo </strong> <i> Dalbergia sissoo</i>
        </p>
        <Flex sx={{ flexDirection: ["column", null, "row", null] }}>
          <Image
            sx={{ flexShrink: 0, alignSelf: "flex-start", pr: 3 }}
            src={shisham}
          />
          <p>
            It is a fast growing multipurpose tree species. It is a valuable
            source of timber, being heavy, very strong, moderately tough, steady
            and very hard. It is used for high quality furniture and cabinet
            making, tool handles, door and window frames etc. The roots, leaves,
            bark and the heartwood are used to cure ailments like diarrhea,
            dysentery etc. The tree is generally planted along roadsides, on
            field boundaries, in shelter belts and on degraded lands.
          </p>
        </Flex>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Palash / Flame of the Forest/ Mothaka chetlu</strong>
          <i> Butea monosperma</i>
        </p>
        <Flex sx={{ flexDirection: ["column", null, "row", null] }}>
          <Image
            sx={{ flexShrink: 0, alignSelf: "flex-start", pr: 3 }}
            src={palash}
          />
          <p>
            it is a medium sized deciduous tree which grows in open country.
            During summer (which is also spring time in India) this tree
            produces clusters of blood-red flowers in great profusion. Bereft of
            leaves at that time, Palash’s fiery orange flowers dazzle the forest
            scene, and hence the name Flame of the Forest. In Ayurveda, its
            bark, flower and resin are used.
          </p>
        </Flex>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Saptaparni </strong> <i> Alstonia scholaris</i>
        </p>
        <Flex sx={{ flexDirection: ["column", null, "row", null] }}>
          <Image
            sx={{ flexShrink: 0, alignSelf: "flex-start", pr: 3 }}
            src={saptaparni}
          />
          <p>
            It is a grand, large evergreen tree that is extremely tolerant of
            pollution. Its bark yields a bitter drug (ditabark), used to treat a
            range of complaints from chronic diarrhoea, dysentery and asthma to
            fevers. People in coastal areas of India use the decoction of the
            bark as an insurance against malaria. Its milky latex is also used
            to treat sores, ulcers etc. The greenish white flowers appearing in
            long pendulous follicles, in clusters are highly fragrant. The wood
            is soft, light and fine-textured. It seasons poorly and is not very
            durable. It is sometimes used to make minor furniture, tea chests
            etc. It is also used for plywood and second class match splints.
          </p>
        </Flex>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Alexandrian Laurel </strong> <i>Calophyllum inophyllum</i>
        </p>
        <Flex sx={{ flexDirection: ["column", null, "row", null] }}>
          <Image
            sx={{ flexShrink: 0, alignSelf: "flex-start", pr: 3 }}
            src={alexander}
          />
          <p>
            This is a very elegant evergreen tree with shining leaves.The tree
            is cultivated for its ornamental value and for shade in many parts
            of India. The reddish brown timber is fairly hard and durable under
            water. It is useful in building ships, fishing boats, for making
            furniture; and also for construction purposes. Almost all parts of
            this tree are medicinal, used to cure a range of ailments. Its root
            is used for treating ulcers; its resin is used as a purgative and
            emetic. A decoction of flowers is given to cure eczema and insanity.
            In Ayurveda, bark and seed oil is used in rheumatoid arthritis, skin
            diseases, dysentery, diarrhea, hemorrhage and dysuria. Seeds yield
            oil, used in soap making and as lubricant.
          </p>
        </Flex>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>The Tree of Heaven </strong> <i>Ailanthus excelsa</i>
        </p>
        <Flex sx={{ flexDirection: ["column", null, "row", null] }}>
          <p>
            This is a fast growing large deciduous tree. In peak summer, when
            everything appears dull and drab in the shades of brown and grey,
            Ailanthus excelsa is one of the few trees that stand apart with lush
            green leaves, defying the blazing sun. The soft, white and lustrous
            wood is used for packing cases, and the debarked wood is used in
            paper industry as a substitute for aspen (Poplar spp.), for printing
            papers. It is extensively used for making matchwood boxes, match
            splints, and in cottage industries for making wooden toys. Leaves
            are rich in protein and are used as a valuable fodder. The bitter,
            astringent bark is used in traditional medicine.
          </p>
        </Flex>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Ashoka/ Sorrowless Tree: </strong> <i>Saraca asoca</i>
        </p>
        <Flex sx={{ flexDirection: ["column", null, "row", null] }}>
          <Image
            sx={{ flexShrink: 0, alignSelf: "flex-start", pr: 3 }}
            src={asoka}
          />
          <p>
            Ashoka is one of the prettiest trees of India. It is a sacred tree
            for the Hindus, the Buddhists and the Jains. It is a small sized
            tree, growing up to 6 m to 9 m height. It has spreading branches
            which form a dense canopy and a shapely crown. Young leaves of this
            tree are beautiful, appear to be made of copper. This tree gets
            flush of new leaves quite regularly and quite often. Flowers are
            borne directly on stem and at the tip of branch. The orange-yellow
            flowers which turn vermillion in a dense formation, also have a
            delicate fragrance that is felt and enjoyed more during the night
            than in the day. Flowers of this tree are pounded inside water and
            used to cure dysentery. Dried flowers are used in treating diabetes.
            Pods are reported to make very good forage for cattle. Bark of this
            tree is widely used in Ayurveda and folk medicine systems from time
            immemorial, especially in treating ailments concerning women, like
            menstrual complaints, uterine hemorrhage etc. Leaves are used to
            treat bleeding of piles and skin diseases. Bark and dried flowers
            are medicinal and are highly useful in uterine disorders. The well-
            known Ayurvedic preparations from it are” Ashokarishta” and
            “Ashokaghrita”.
          </p>
        </Flex>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Champaka / Sampangi/ Sampige </strong>
          <i>Magnolia champaca</i>
        </p>
        <Flex sx={{ flexDirection: ["column", null, "row", null] }}>
          <Image
            sx={{ flexShrink: 0, alignSelf: "flex-start", pr: 3 }}
            src={champaka}
          />
          <p>
            Champaka is a contribution from the Oriental realm, and grows in
            moist and wet forests of the Western Ghats and North East India.
            Though this tall handsome evergreen tree also yields valuable
            timber, it is seldom cut as it is considered sacred. It is commonly
            cultivated in the neighborhood of temples for its yellow and sweetly
            scented flowers. Flowers are used for medicinal purposes and the
            attar extracted is used in perfume industry. Yellow dye extracted
            from flowers is used for colouring foodstuff. Various parts of the
            tree find place in traditional medicines.
          </p>
        </Flex>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Bakula/ Indian Medlar </strong> <i>Mimusops elengi</i>
        </p>
        <Flex sx={{ flexDirection: ["column", null, "row", null] }}>
          <Image
            sx={{ flexShrink: 0, alignSelf: "flex-start", pr: 3 }}
            src={bakula}
          />
          <p>
            This is a large elegant evergreen tree with a straight trunk. This
            tree yields a hard and durable wood, used for making furniture,
            carts, boards etc. The star shaped flowers of this tree are
            pale-white, fragrant. They are shed during the night, and in the
            morning one can find the ground beneath the tree covered with
            flowers. For this reason, Bakula is often planted on the sides of
            sacred groves. The greatest virtue of the flower is that it retains
            its sweet scent even when dry. Fruit is a berry with a single seed
            and is edible. Fruits, flowers and leaves of the tree are used as
            medicine. In Ayurveda, its bark, flower and fruit are used for
            curing leprosy, uterine disorders, cardiac diseases, eye diseases,
            etc.
          </p>
        </Flex>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Paarijaath/ Night Jasmine </strong>
          <i>Nyctanthes arbortristis</i>
        </p>
        <p>
          It is a small tree that grows all along the foothills of Himalayan
          tract and the Peninsular India. This deciduous tree has simple dull
          green foliage. It is an excellent fuel wood. Leaves are used in
          traditional medicine. It is held sacred and is generally grown in
          temples. It blooms in the evening and its flowers fall off soon after.
          Flowers are beautiful, and the scent of its flowers is exhilarating.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Indian Tulip</strong> <i>Poovarasu Thespesia populnea</i>
        </p>
        <p>
          It is a medium sized fast growing evergreen tree, commonly found in
          South India. It is a thrifty tree, growing along saline coasts to arid
          interior India. Because of its large rounded crown, this shade giving
          tree is sometimes called ‘umbrella tree’. Its large leaves are used
          for packing edible items. Its bark, leaves, flowers, fruits and roots
          are used in local medicines. Bark also yields good fibre. Its wood is
          hard, durable and water resistant, used in making cartwheels, boats,
          furniture, and gun stocks. Root, fruits and leaves are used in curing
          scabies, psoriasis and other cutaneous diseases. Bark is astringent
          and anti-dysenteric. The brilliant yellow flowers of this tree are
          splendid, large and showy, with a purple eye at the centre turning
          pink as the flowers age. This tree flowers almost throughout the year,
          but chiefly during cold weather. Honey bees swarm its flowers for
          nectar.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Indian Laburnum</strong> <i>Cassia fistula</i>
        </p>
        <p>
          As this tree has the softest cascade of yellow flowers drooping from
          every branch, it is also known as Golden Shower. Its light green buds
          at the tips and light green stalks highlight the beauty of the
          flowers. These flowers last long and when ultimately they are about to
          wither away, its long light green leaves reappear. Thus the whole tree
          is either draped in lush green leaves or covered with bright yellow
          flowers, and as a result, it always remains a highly decorative tree.
          The Hindus use the flowers in religious ceremonies. Its wood is hard
          and durable, used in making agricultural implements, posts and beams
          of buildings, etc. Fruit pulp is a purgative. A decoction made from
          roots is used in curing stomach ailment. Bark of this tree is used in
          tanning hides, dyeing leather and jute fibres.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Pride of India / Queen’s Flower </strong>
          <i>Lagerstroemia flos-reginae</i>
        </p>
        <p>
          It is a fast growing large deciduous tree with spreading branches. Its
          purplish blooms are strikingly beautiful, giving it the name ‘Pride of
          India’. Profuse flowering occurs a little late in the summer (May –
          June), and covers the entire tree. This tree yields valuable timber.
          As its wood is hard, strong, and durable under water; it is used in
          India for making boats, canoes, carts, ammunition boxes, and also in
          buildings. Seeds are narcotic. In Ayurveda, bark, leaf, fruit and seed
          are extensively used to cure a number of ailments including diabetes.
          Leaves, bark, dried fruits and roots are also used in other indigenous
          systems of medicine. Tannin can be extracted from fruits and leaves.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Wood Apple </strong> <i>Limonia acidissima L.</i>
        </p>
        <p>
          It is a medium sized deciduous tree commonly found throughout the
          drier parts of the Peninsular India. The light brown wood of this tree
          is used in construction and in the making agricultural implements. Its
          fruit pulp is much valued as a food and also as medicine. It is eaten
          plain or made into a sherbet, or preserved as jam. This tree is often
          cultivated in rural areas for its fruits.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Kachnar / Camel foot Tree </strong> <i>Bauhinia variegata</i>
        </p>
        <p>
          It is a medium sized, deciduous, fast growing tree. With large showy,
          orchid- like flowers which are full of fragrance, it is popularly
          known as ‘Orchid Tree’. These flowers are also edible. Leaves of this
          tree are used as beedi wrappers. Seeds yield oil. Timber is hard and
          used in making agricultural implements. Bark of this tree is used in
          tanning and dying. It is also medicinal. It is a good fodder tree and
          withstands heavy lopping.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Mahua </strong> <i>Madhuca longifolia</i>
        </p>
        <p>
          It is a medium sized deciduous tree with spreading branches and a
          rounded crown. It is found in many parts of deciduous forests in
          India. For many tribal groups in Central India, ‘Mahua’ flowers are a
          major source of food. Sugar is extracted from its flowers. Flowers are
          also used to distill a potent and popular liquor. Fruits are eaten
          afresh or dried; its outer coat is cooked as a vegetable. A thick oil
          is extracted from its kernel; hence the name ‘Butter Tree’. This tree
          has medicinal uses. In Ayurveda, flower and seed oil are used for
          curing oedema, leprosy, polyuria, diarrhea, and gout. Bark is used in
          treating stomach ache of horses and bone fracture of cattle. Oil cakes
          from this tree is a good manure and when spread on lawn, eradicate
          worms.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Malabar Neem/ Melia dubia </strong>
        </p>
        <p>
          It is one of the fastest growing timber trees of tropical India,
          attaining great height with a spreading crown. Fodder from this tree
          is relished by cattle, goats and sheep. The wood is used in making
          packing cases, ceiling planks, match box and musical instruments and
          catamarans. Apart from being an excellent source of commercial
          plywood, it is a good species for agro-forestry purposes.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Kadamba </strong> <i>Anthocephalus cadamba</i>
        </p>
        <p>
          It is a tall, straight-boled, deciduous tree with whorled,
          horizontally spreading branches. Though it is basically a riparian
          species, Its wood is used in making dugouts, ply wood, packing cases
          paper etc. Its bark has medicinal value and leaves are fed to cattle.
          It is a much valued ornamental tree for its fragrant yellow-orange
          flowers. Bark of this tree is a febrifuge and tonic while its fruit is
          considered an aphrodisiac. An essential oil is extracted from the
          flowers. Fruits are distilled to make wine and vinegar. It is planted
          near houses, temples, sacred places, in gardens and park and along
          avenues.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Arjuna </strong> <i>Terminalia arjuna</i>
        </p>
        <p>
          Arjuna is a large handsome tree of tropical and subtropical forests,
          naturally occurring along the banks of rivers, streams, ravines and
          dry watercourses. This tree is exceptionally beautiful with its often
          buttressed stem, smooth bark, horizontal branches and drooping
          branchlets. Arjuna’s wood is hard and strong; indeed is harder than
          Teak, and stronger too. It is used in making boats, ships, carts,
          posts, beams, agricultural implements, furniture and cabinets. Its
          bark yields a number of Ayurvedic medicines. It has been used in the
          treatment for heart diseases by the Hindu Physicians of yore and its
          curative properties have been fully supported even by modern
          investigations. Bark is very much used for tanning purposes too,
          besides being a potential source of oxalic acid, an important
          ingredient in pharmaceutical and textile industries. A transparent
          brownish/golden coloured gum is obtained from the bark of this tree,
          which is edible, and very nutritive. It is also used as an adhesive.
          Silkworms that produce tassar silk feed on the leaves of this tree.
          Its fragrant flowers of are dull yellow in colour, and contain nectar
          in good proportion.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Bahara </strong> <i>Termnalia bellirica</i>
        </p>
        <p>
          It is a large deciduous tree, buttressed at the base, found in most
          parts of plains and low hills of India, barring very moist and desert
          areas. Wood of this tree is hard. Though not durable, it is used as a
          timber in certain parts of India, but avoided for religious reasons in
          most parts. Bark of this tree is used in anaemia, leucoderma etc. Its
          fruit is well known as ‘myrobolan’, used in treatments for diseases of
          eyes, nose heart and bladder.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Haritaki </strong> <i>Terminalia chebula</i>
        </p>
        <p>
          It is a multipurpose medicinal tree. Bark of this tree is diuretic,
          and cardio tonic. Fruits are laxative and used for treating wounds,
          ulcers, inflammation etc. Natural regeneration of this species is
          abysmally low in most forests as both raw and ripe fruits are
          harvested from trees. Such dysgenic extraction has endangered this
          species.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Bilwa: Golden Apple Tree </strong> <i>Aegle marmelos</i>
        </p>
        <p>
          This medium sized (10 to 12 m tall) sacred tree is common in India,
          occurring in sub–Himalayan tracts, Central and South India. In the
          peninsular India it is restricted to the dry and moist deciduous
          forests of the Eastern and Western Ghats. The wood of Bilwa is hard,
          with a strong aromatic scent when freshly cut. Its greenish-white
          flowers are wonderfully fragrant. Its fruits, when ripe contain
          reddish–orange pulp, which is rich in mucilage. A large number of
          hairy seeds, up to 1 cm in length are embedded in the pulp. Almost
          every part of this tree has medicinal properties, used in different
          systems of treatment, especially in Ayurveda, Unani, and in home
          remedies. The pulp of Bilwa fruit is astringent, and is consumed as
          ‘sherbet’ (sweetened drink). It is believed that this fruit pulp
          increases the lifespan. It is believed to be good for the skin and
          vision too. Root bark is reported to be useful in cardiac diseases.
          Unfortunately, despite its usefulness, and despite it being a sacred
          plant, Bilwa has become one of the endangered trees of India.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Putranjiva/Indian Amulet Tree </strong>
          <i>Drypetes roxburghii</i>
        </p>
        <p>
          It is a medium sized graceful evergreen tree with dense foliage, and
          low spreading, drooping branches. It is a sacred tree, commonly found
          cultivated for its medicinal value. Its wood is shining, fairly hard
          and close grained. It is used for turning, making tools and for
          structural works. Leaves and seeds are used in making medicine for
          curing cold and cough. Leaves of this tree are a good fodder, and its
          seeds yield oil which is sometime used for burning. Seeds are strung
          in necklaces and rosaries.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Kokko / East Indian Walnut </strong> <i>Albizzia lebbeck</i>
        </p>
        <p>
          It is one of the most promising trees of India.iDgestibility of its
          leaves and tender twigs for cattle is considerably higher than that of
          most other fodder trees. It is also an excellent fuel wood species and
          a source for charcoal. Its wood is suitable for construction,
          furniture and veneer. Gum from the tree is valued.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Kejri </strong> <i>Prosopis cineraria</i>
        </p>
        <p>
          It is an extremely drought tolerant species. This tree produces leaves
          in extremely dry summer months where most other trees remain leafless.
          Its leaves provide an excellent, nutritious fodder. Kejri pods contain
          a dry sweet pulp, which is also a source of good fodder. Green pods
          called ‘sangar’ are boiled and dried for human consumption. Its
          flowers are valuable for honey production. This tree yields an edible
          gum. Its bark and flowers are used for medicinal purposes too. Wood is
          used for making agricultural implements, in buildings etc. It is also
          an excellent source of fuel wood.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Chandan </strong> <i>Santalum album</i>
        </p>
        <p>
          The close grained heartwood of this tree is strongly scented and used
          for carving. A highly priced essential oil is obtained from its
          aromatic heartwood and roots, used in perfumery and medicine. In
          Ayurveda, heart wood and oil are used to treat fever, polyuria,
          poisoning, debility and dysuria etc. It is an ingredient of
          “Chandanadi Bati”,”Chandanadi Churna” and ‘Chandanasava’. Heart wood
          is also used as an unguent in religious ceremonies. Sandal wood is the
          costliest timber in India, with a price tag of around Rs.7,000 per kg
          in current market rate. Heartwood of Sandal on steam distillation
          yields 2.5-6.2 per cent fragrant Sandal oil of high perfumery value.
          Currently Sandal oil is sold in the market at the rate of
          Rs.70,000-100,000 per kg.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Red Sanders / Yerra chandan / Rakta Chandan </strong>
          <i>Pterocarpus santalinus</i>
        </p>
        <p>
          This endemic and endangered tree grows on dry hilly and rocky terrain
          at elevations of 150-900 m. The timber is hard, refractory, durable
          and heavily impregnated with red dye. This dark claret wood is used
          for carving, and as a source for edible dye. Paste made out of its
          heartwood is used in worship; for cosmetic purpose, and also for
          treating intrinsic hemorrhage, fracture, chronic fever etc. Leaves are
          considered to be a good fodder, but it may not be a wise use of this
          valuable species for this purpose. Its large yellow flowers offer
          pollen and nectar to the honey bees.
        </p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <p>
          <strong>Gamhar / Shivane</strong> <i>Gmelina arborea</i>
        </p>
        <p>
          Multiplicity of its uses and fast growing nature of this species
          receive great appreciation within Forest Departments as well as among
          growers who take up large scale plantations of this species. Its
          lustrous and smooth wood is used mainly for light construction works
          and for pulp. Several parts of this tree are used for medicine. Leaves
          are used as fodder. Root is extensively used in Ayurveda.
        </p>
      </Box>
    </LayoutHome>
  );
};

export default EachOneTeachOne;
